:root {
  /** primary **/
  --ion-color-primary: #FF2D56;
  --ion-color-primary-rgb: 255, 45, 86;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #e6294d;
  --ion-color-primary-tint: ##ff4267;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #202122;
  --ion-color-dark-rgb: 48, 48, 48;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #161718;
  --ion-color-dark-tint: #363738;

  /** medium **/
  --ion-color-medium: #595959;
  --ion-color-medium-rgb: 89, 89, 89;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #454545;
  --ion-color-medium-tint: #6E6E6E;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

body.dark 
{
    --ion-border-color        : var(--ion-color-dark-shade);
    --ion-background-color    : var(--ion-color-dark);
    --ion-background-color-rgb: var(--ion-color-dark-rgb);
    --ion-text-color          : var(--ion-color-light);
    --ion-text-color-rgb      : var(--ion-color-light-rgb);
/* 
    --ion-color-step-50: #232323;
    --ion-color-step-100: #2e2e2e;
    --ion-color-step-150: #3a3a3a;
    --ion-color-step-200: #454545;
    --ion-color-step-250: #515151;
    --ion-color-step-300: #5d5d5d;
    --ion-color-step-350: #8b8b8b;
    --ion-color-step-400: #747474;
    --ion-color-step-450: #7f7f7f;
    --ion-color-step-500: #8b8b8b;
    --ion-color-step-550: #979797;
    --ion-color-step-600: #a2a2a2;
    --ion-color-step-650: #aeaeae;
    --ion-color-step-700: #b9b9b9;
    --ion-color-step-750: #c5c5c5;
    --ion-color-step-800: #d1d1d1;
    --ion-color-step-850: #dcdcdc;
    --ion-color-step-900: #e8e8e8;
    --ion-color-step-950: #f3f3f3; */


}