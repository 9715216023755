input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active
{
    transition: background-color 5000s ease-in-out 0s;
}

input, textarea
{
    outline: none;
    box-shadow:none !important;    
}

input:focus, select:focus, textarea:focus, button:focus
{
    outline: none;
    box-shadow:none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active 
{
  border: 0px solid green;
  /*-webkit-text-fill-color: green;*/
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  -box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* 
input:-webkit-autofill {
    -webkit-text-fill-color: yellow !important;
} */

/* .ios
{
    --placeholder-opacity:0.1;

}

.md
{
    --placeholder-opacity:0.1;
} */

ion-modal.fullscreen
{
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
}

ion-content.bgimage
{

    --background: url("../theme/img/bg.jpg") 0 0/100% 100% no-repeat;
/*
    background-position: right top;
  */  
    /*
    filter: blur(0px);
    -webkit-filter: blur(0px);
    z-index: -10000; */
}

ion-popover.auto
{
    --width	: auto
}

ion-item {
    --transition: none;
}

.bbc-aside
{
    background-color: "red";
}

body.dark 
{
    --bbc-table-background-color:var(--ion-color-dark-tint)
}

.page_property_list_modal .modal-wrapper
{
    width: 80% !important;    
    height: 80% !important;
}

.page_property_list_modal .ion-backdrop
{
    --backdrop-opacity: 0.33;
}

.action-sheet-container.sc-ion-action-sheet-md, .action-sheet-container.sc-ion-action-sheet-ios
{
    justify-content: center !important;
}

.bc .popover-content
{
    max-width: unset !important;
    width: 430px !important;
}

.alert-wrapper
{
    max-width: unset !important;
    width: 400px !important;
}

.alert-checkbox-group::-webkit-scrollbar
{
    width: 1em;
    display: block !important;
}

.alert-checkbox-group::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 5px;
}

.alert-checkbox-group::-webkit-scrollbar-thumb
{
    background-color: darkgrey;
    border-radius: 5px;
}

.alert-radio-group::-webkit-scrollbar
{
    width: 1em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 5px;
}

.alert-radio-group::-webkit-scrollbar-thumb
{
    background-color: darkgrey;
    border-radius: 5px;
}
